<template>
  <div>
    <v-icon
      small
      class="mr-2"
      @click="updateBankUser"
      v-if="checkUserPermission('EDIT')"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      color="red"
      small
      @click="dialogDelete = true"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('DELETE')"
      >mdi-delete
    </v-icon>
    <dialog-delete
      :darkmode="darkmode"
      :dialog-delete="dialogDelete"
      v-on:closeDelete="cancelDelete"
      v-on:deleteItemConfirm="deleteItem"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    DialogDelete: () =>
      import("../../../../components/shared/notify/DialogDelete"),
  },
  props: ["darkmode", "item", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    async updateBankUser() {
      console.log("this.item",this.item);
      await this.fetchCurrentBankUser(this.item);
      await this.fetchListPermission();
      await this.fetchParentBankUser();
      await this.fetchPopUpBankUser(true);
    },
    cancelDelete() {
      this.dialogDelete = false;
    },
    async deleteItem() {
      await this.deleteBankUser({
        vm: this,
        data: { deleteItems: [{ _id: this.item._id }] },
      });
      await this.fetchListBankUser(this.params);
      this.dialogDelete = false;
    },
    ...mapActions("$_bankUser", [
      "fetchPopUpBankUser",
      "fetchListBankUser",
      "deleteBankUser",
      "fetchCurrentBankUser",
      "fetchParentBankUser",
    ]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
